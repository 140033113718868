<script setup>
import InputLabel from '@/company/Components/InputLabel.vue';
import InputText from '@/company/Components/InputText.vue';
import InputError from '@/company/Components/InputError.vue';
import { onMounted, ref } from 'vue';
import { nextTick } from 'vue';
import { ErrorBoundary } from '@/common/Components';

defineEmits(['update:modelValue', 'focus', 'blur', 'input', 'change', 'keyup', 'keydown', 'maska']);

const props = defineProps({
    type: {
        type: [Object, String],
        default: 'text',
        required: false,
    },
    as: {
        type: [Object, String],
        default: 'text',
        required: false,
    },
    modelValue: {
        default: null,
        required: false,
    },
    label: {
        type: [String, Boolean],
        default: null,
    },
    help: {
        type: String,
        default: null,
    },
    description: {
        type: String,
        default: null,
    },
    name: {
        type: String,
        default: null,
    },
    errorMessage: {
        type: String,
        default: null,
    },
    options: {
        type: Array,
        default: null,
    },
    autofocus: {
        type: Boolean,
        default: false,
    },
    requireInput: {
        type: Boolean,
        default: false,
    },
    maxQuantityReached: {
        type: Boolean,
        required: false,
    },
    placeholder: {
        type: String,
        required: false,
    },
});

const inputElement = ref(null);

onMounted(() => {
    nextTick(() => {
        if (props.autofocus) {
            inputElement.value?.focus?.();
        }
    });
});
</script>
<template>
    <div>
        <slot name="label" v-if="!type?.props?.label">
            <InputLabel :require-input="requireInput" :for="name" :value="label" :help="help" />
        </slot>

        <slot name="description" v-if="!type?.props?.description">
            <div v-if="description" class="mb-2 text-sm opacity-50">{{ description }}</div>
        </slot>

        <template v-if="typeof type === 'string'">
            <InputText
                ref="inputElement"
                :placeholder="placeholder"
                v-bind="{ ...$attrs }"
                :type="type"
                :model-value="modelValue"
                :id="$attrs['id'] ?? name"
                :name="$attrs['name'] ?? name"
                @update:model-value="$emit('update:modelValue', $event)"
                @focus="$emit('focus', $event)"
                @blur="$emit('blur', $event)"
                @input="$emit('input', $event)"
                @change="$emit('change', $event)"
                @keydown.stop="$emit('keydown', $event)"
                @keyup="$emit('keyup', $event)"
                @maska="$emit('maska', $event)"
            />
        </template>

        <template v-else>
            <ErrorBoundary>
                <component
                    :is="type"
                    ref="inputElement"
                    v-bind="{
                        ...$attrs,
                        label,
                        errorMessage,
                        description,
                        ...(type?.props?.as ? { as } : {}),
                    }"
                    :name="$attrs['name'] ?? name"
                    :id="$attrs['id'] ?? name"
                    :model-value="modelValue"
                    :options="options"
                    :max-quantity-reached="maxQuantityReached"
                    @update:model-value="$emit('update:modelValue', $event)"
                    @focus="$emit('focus', $event)"
                    @blur="$emit('blur', $event)"
                    @input="$emit('input', $event)"
                    @change="$emit('change', $event)"
                    @keydown.stop="$emit('keydown', $event)"
                    @keyup="$emit('keyup', $event)"
                    @maska="$emit('maska', $event)"
                />
            </ErrorBoundary>
        </template>

        <slot name="errors" v-if="!type?.props?.errorMessage">
            <InputError :message="errorMessage" class="m-2 tracking-normal" />
        </slot>
    </div>
</template>
